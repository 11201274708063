
export const environment = {
    production: true,
    API_URL: 'https://onspot-backend.herokuapp.com/api/v1',
    WEB_URL: 'https://test.onspot-app.de/',
    S3_BASE_URL: 'https://onspot-assets.s3-eu-west-1.amazonaws.com/',
    S3_BUCKET_NAME: 'onspot-assets',
    S3_BUCKET_REGION: 'eu-west-1',
    AWS_ACCESS_KEY_ID: 'AKIAX7E5PIRLAKAW2YBY',
    AWS_SECRET_ACCESS_KEY: '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',
    AWS_SIGNATURE_VERSION: 'v4',
    AWS_API_VERSION: '2006-03-01',
    STRIPE_KEY: 'pk_test_51Hc55sGuZ1CahdjBe03vA983uM5qWGRS5Ykbi1qTTfh1bNE2jFtNMBfoCTrvVYf4FDWtP1XZiGe5n3Hb4moxB8ol00FRkheAeg',
    PAY_PAL_SANDBOX: true,
    PAY_PAL_CLIENT_ID: 'AZzh3fG6TriDjrAt1g5_bw_fqIecMRTyzdaMhNCcj73pDX6E7oXdOq2NdCnaI6bLbfqAtkfnpmDiES9t',
    MAX_SHEETS_AMOUNT: '10'
  };
